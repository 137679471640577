import $ from 'jquery'

class TabsContent extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.props = this.getInitialProps()
    this.state = this.getInitialState()
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()
  }

  getInitialProps () {
    const data = {}
    // try {
    //   data = JSON.parse($('script[type="application/json"]', this).text())
    // } catch (e) {}
    return data
  }

  getInitialState () {
    return {}
  }

  resolveElements () {
    // First Tab
    $('.list.list-1').hover(function () {
      // list
      $(this).addClass('active')
      $('.list-2,.list-3,.list-4').removeClass('active')
      // panel
      $('.panel-1').removeClass('panelhide').addClass('.paneldisp')
      $('.panel-2,.panel-3,.panel-4').removeClass('.paneldisp').addClass('panelhide')
    })

    // Second Tab
    $('.list.list-2').hover(function () {
      // List
      $(this).addClass('active')
      $('.list-1,.list-3,.list-4').removeClass('active')
      // panel
      $('.panel-2').removeClass('panelhide').addClass('.paneldisp')
      $('.panel-1,.panel-3,.panel-4').removeClass('.paneldisp').addClass('panelhide')
    })

    // third Tab
    $('.list.list-3').hover(function () {
      // List
      $(this).addClass('active')
      $('.list-1,.list-2,.list-4,.five_list,.six_list,.seven_list').removeClass('active')
      // panel
      $('.panel-3').removeClass('panelhide').addClass('.paneldisp')
      $('.panel-1,.panel-2,.panel-4').removeClass('.paneldisp').addClass('panelhide')
    })

    // Fourth Tab
    $('.list.list-4').hover(function () {
      // List
      $(this).addClass('active')
      $('.list-1,.list-3,.list-2').removeClass('active')
      // panel
      $('.panel-4').removeClass('panelhide').addClass('.paneldisp')
      $('.panel-1,.panel-3,.panel-2').removeClass('.paneldisp').addClass('panelhide')
    })
  }

  bindFunctions () { }

  bindEvents () { }

  connectedCallback () { }
}

window.customElements.define('flynt-tabs-content', TabsContent, {
  extends: 'div'
})

$(document).on('click', '.flynt-tabs-content-mobile .panel', function () {
  $(this).children('.extra-content').toggleClass('active')
})
